<template>

<div class="mobile-page-layout">

    <!-- <div class="mobile-page-header">
        <div class="mobile-page-header-row">
            <div class="mobile-page-header-right">
                <router-link :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                    <i class="header-uil-icon uil uil-angle-right-b"></i>
                </router-link>
                <div class="mobile-page-header-icon">
                    <svg class="header-svg-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path class="fa-primary" d="M448 335.1c0 13.42-15.52 20.88-25.1 12.49L368 305.3l-54 43.2C303.5 356.8 288 349.4 288 335.1V96h160V335.1z" />
                        <path class="fa-secondary" d="M512 144v288c0 26.5-21.5 48-48 48h-416C21.5 480 0 458.5 0 432v-352C0 53.5 21.5 32 48 32h160l64 64H288v239.1c0 13.42 15.52 20.88 25.1 12.49L368 305.3l54 43.2C432.5 356.8 448 349.4 448 335.1V96h16C490.5 96 512 117.5 512 144z" />
                    </svg>
                </div>
                <div class="mobile-page-header-text">
                    دسته‌بندی اخبـار
                </div>  
            </div>
            <div class="mobile-page-header-actions">

                <div class="mobile-page-header-menu">
                    <template>
                        <div class="text-center">
                            <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <div class="mobile-page-header-icon pointerv margin-0" v-bind="attrs" v-on="on">
                                    <i class="header-material-icon mdi mdi-dots-vertical"></i>
                                </div>
                            </template>
                            <v-list class="header-help-list">
                                <v-list-item @click.prevent="get_modal('search')">
                                    <v-list-item-title>جستجو</v-list-item-title>
                                </v-list-item>

                            </v-list>
                            </v-menu>
                        </div>
                    </template>                 
                </div>
            </div>
        </div>
    </div> -->

    <div class="home-layout">
        <div v-if="slider.items" class="news-slider">
            <router-link :to="{ name: 'HomeIntro'}" class="news-slider-back-btn">
                <i class="uil uil-angle-right-b"></i>
            </router-link>
            <img class="news-slider-img" :src="slider.items.data[0].image">
            <div class="news-slider-content">
                <router-link class="news-slider-tag" :to="{ name: 'ApplicationNews' , params: { category_id: slider_cat, }}">{{slider.items.data[0].category_title}}</router-link>  
                <router-link class="news-slider-title" :to="{ name: 'ApplicationNewsContent' , params: { id: slider.items.data[0].id, }, query: { subject: 'tgju-news-' + slider.items.data[0].id }}">{{ slider.items.data[0].title }}</router-link>
                <p class="news-slider-des">{{slider.items.data[0].summary}}</p>
            </div>
        </div>

        <div class="home-row">
            <router-link v-for="item in list" :key="item.id" :class="'home-col '+item.class" :to="{ name: 'ApplicationNews' , params: { category_id: item.id }}">
                <div class="home-widget">
                    <div class="home-widget-in">
                        <img class="home-widget-img" :src="'https://static.tgju.org/views/default/images/tgju/app/news/'+item.image">
                        <h2 class="home-widget-title">{{item.name}}</h2>
                    </div>
                </div>  
            </router-link>  
        </div>
        <NewsRow :cat="'اخبار-ویژه'" :count="5" :title="'اخبـار ویـژه'" :type="'slug'" />
    </div>
</div>
</template>

<script>
import NewsRow from '@/components/Inc/newsRow.vue'

export default {
    name: 'NewsIntro',
    components: {
        NewsRow
    },
    data: function () {
        return {
            list:[
                {
                    id:'اخبار-ویژه',
                    name:'اخبار ویژه',
                    image:'ویژه.svg'
                },
                {
                    id:'تحلیل-بنیادین',
                    name:'تحلیل بنیادین',
                    image:'تحلیل بنیادین.svg'
                },
                {
                    id:'تحلیل-تکنیکال',
                    name:'تحلیل تکنیکال',
                    image:'تحلیل تکنیکال.svg'
                },
                {
                    id:84008,
                    name:' گزارش بازارها',
                    image:'گزارش بازارها.svg'
                },
                {
                    id:93970,
                    name:'  آکادمی',
                    image:'آکادمی.svg'
                },
                {
                    id:93964,
                    name:'طلا و سکه',
                    image:'طلا و سکه.svg'
                },
                {
                    id:93965,
                    name:'ارزی',
                    image:'ارزی.svg'
                },
                {
                    id:93966,
                    name:'ارزهای دیجیتال',
                    image:'ارزهای دیجیتال.svg'
                },
                {
                    id:93967,
                    name:'نفت و انرژی',
                    image:'انرژی.svg'
                },
                {
                    id:93968,
                    name:'بورس',
                    image:'بورس.svg'
                },
                {
                    id:83979,
                    name:'مسکن',
                    image:'مسکن.svg'
                },
                {
                    id:83980,
                    name:'خودرو',
                    image:'خودرو.png'
                },
                {
                    id:93969,
                    name:'بانکی',
                    image:'بانک و بیمه.svg'
                },
                {
                    id:83986,
                    name:'تولید و تجارت',
                    image:'تولید و تجارت.svg'
                },
                {
                    id:83983,
                    name:'جهان',
                    image:'جهان.svg'
                },
                {
                    id:83981,
                    name:'قیمت‌ها',
                    class: 'home-col-local-markets',
                    image:'قیمت ها.svg'
                },
                {
                    id:83984,
                    name:'فناوری',
                    class: 'home-col-local-markets',
                    image:'فناوری.svg'
                }
            ],
            slider:[],
            slider_cat:0
        }
    },
    mounted() {
        this.getSlider();
        this.$store.commit('setAds', true);
    },
    computed: {},
    methods: {
        getSlider(){
            $.ajax({
                url: 'https://api.tgju.org/v1/news/list?limit=1&tag=%D8%AE%D8%A8%D8%B1-%DB%8C%DA%A9',
                method: 'get',
                complete: (res) => {
                    this.slider = res.responseJSON.response;    
                    this.slider_cat = Object.keys(this.slider.categories)[0];   
                }
            });
        },
    },
}
</script>